.wrap {
  background: rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  position: relative;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  padding: 0;
  border: 2px solid rgb(76, 163, 110);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.body {
  padding-block: 16px;
  padding-inline: 32px;

}

.closeButton {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  right: 0;
  top: 0;
  border: none;
  cursor: pointer;
  background: none;
  color: rgb(0, 0, 0, 0.6);
}

.closeButton:hover {
  color: rgb(0, 0, 0, 1);
}

.title {
  background-color: rgb(76, 163, 110);
  padding-block: 8px;
  padding-inline: 32px;
  font-style: normal;
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
  color: #ffffff;
}